import "./About.css";
function About(props) {
  return (
    <>
      <div id="about-container" className="about-container">
        <div className="about-section">
          <div className="about-left">
          <div className="profilePic"></div>
          </div>
          <div className="about-right">
            <p>
              "I was born on the west coast of Scotland, but this served only as
              a springboard to years of extensive travel through many parts of
              our world. Now, however, I have landed in my beloved London. My
              professional life has been as extensive as my travel, culminating
              in a career in the financial industry which gave me the
              opportunity to converse with clients from all over Europe.
              Throughout all of my life adventures I have always carried with me
              my voice. That part of me that so many have reflected to me as
              being easy going, warm and friendly. And so I have decided to
              finally embrace my passion to use my unique voice with a Scottish
              lilt, to communicate to the world my particular expression and
              character."
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
