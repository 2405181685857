import "./Head.css";
import ME from "../images/me.jpg";
import { useEffect } from "react";
import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

function getOffset(el) {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
    bottom: rect.bottom,
  };
}

const appHeight = () => {
  const doc = document.getElementById("head-container");
  doc.style.setProperty("--app-height", `${window.innerHeight}px`);
};
//window.addEventListener("resize", appHeight);

document.addEventListener("scroll", function () {
  var headcontainer = document.getElementById("head-container");
  var myHeight = window.innerHeight;
  var bottom = getOffset(headcontainer).bottom;
  var per = bottom / myHeight - 0.2;
  headcontainer.style.opacity = per;
  if (bottom >= myHeight) {
    headcontainer.style.opacity = 0.9999;
  }
  if (per < 0) {
    headcontainer.style.opacity = 0;
  }
  var header = document.getElementById("header");

  header.style.top = bottom - 75 + "px";
  header.style.bottom = "";
  //header.style.setProperty("--headerBottom", -(bottom - 75) + "px");
  header.style.display = "flex";

  if (bottom >= myHeight) {
    header.style.bottom = 0;
    header.style.top = "";
  }

  if (bottom <= 75) {
    var header = document.getElementById("header");

    header.style.display = "flex";
    header.style.top = 0;
    //document.getElementById("head-container").style.visibility = "hidden";
  } else {
    var header = document.getElementById("header");
    //header.style.display = "none";
    //document.getElementById("head-container").style.visibility = "visible";
  }
  /*
  const id = "about-container";
  const yOffset = -75;
  const element = document.getElementById(id);
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    if (getOffset(element).bottom - 80 <= element.offsetHeight) {
    //alert("Showing");
    document.getElementById("menu-about").className = "menu-selected";
  } else {
    document.getElementById("menu-about").className = "";
  }
  */
  //setSelected("about-container", "menu-about");
  //setSelected("head-container", "menu-home");
  //setSelected("services-container", "menu-services");
  setSelected();
});

//function setSelected(containerID, menuID) {
var selectedID = "";
function setSelected() {
  /*
  const id = containerID;
  const yOffset = -74;
  const element = document.getElementById(id);
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
*/

  var sections = [
    { container: "head-container", menu: "menu-home" },
    { container: "services-container", menu: "menu-services" },
    { container: "about-container", menu: "menu-about" },
  ];
  for (let index = 0; index < sections.length; index++) {
    var div = document.getElementById(sections[index]["menu"]);
    if (div.getAttribute("class") == "menu-selected") {
      selectedID = sections[index]["menu"];
    }
  } //end for
  //console.log(selectedID);

  for (let index = sections.length - 1; index >= 0; index--) {
    var section = document.getElementById(sections[index]["container"]);
    var menu = sections[index]["menu"];

    if (getOffset(section).bottom - 80 <= section.offsetHeight) {
      var ul = document.getElementById("ul-menu");
      for (let index = 0; index < ul.childNodes.length; index++) {
        if (!(ul.childNodes[index].getAttribute("id") == menu)) {
          //console.log(ul.childNodes[index].getAttribute("id"));
          ul.childNodes[index].className = "";
          ul.childNodes[index].removeAttribute("class");
        } //end if
      } //end for

      if (!(document.getElementById(menu).className == "default-selected")) {
        document.getElementById(menu).className = "menu-selected";
      }
      break;
    }
  } //end for sections

  /*
  if (getOffset(element).bottom - 80 <= element.offsetHeight) {
    var ul = document.getElementById("ul-menu");
    for (let index = 0; index < ul.childNodes.length; index++) {
      if (!(ul.childNodes[index].getAttribute("id") == menuID)) {
        //console.log(ul.childNodes[index].getAttribute("id"));
        ul.childNodes[index].className = "";
      } //end if
    } //end for

    if (!(document.getElementById(menuID).className == "menu-selected")) {
      document.getElementById(menuID).className = "menu-selected";
    }
  }
  */
}

function Head() {
  useEffect(() => {
    //appHeight();
    document.getElementById("menu-about").addEventListener("click", () => {
      //document.getElementById("about-container").scrollIntoView({ behavior: "smooth" });

      const id = "about-container";
      const yOffset = -74;
      const element = document.getElementById(id);
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    });

    document.getElementById("menu-services").addEventListener("click", () => {
      const id = "services-container";
      const yOffset = -74;
      const element = document.getElementById(id);
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    });

    document.getElementById("menu-home").addEventListener("click", () => {
      document
        .getElementById("head-container")
        .scrollIntoView({ behavior: "smooth" });
      /*
      const id = "head-container";
      const yOffset = -74;
      const element = document.getElementById(id);
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
      */
    });
  }, []);

  return (
    <>
      <div className="header" id="header">
        <ul id="ul-menu">
          <li className="default-selected" id="menu-home">
            Home
          </li>
          <li id="menu-services">Services</li>
          <li id="menu-about">About</li>
          <li id="menu-contact">Contact</li>
        </ul>
      </div>
      <div className="head-container" id="head-container">
        <video playsInline src="/videos/Scotland2.mp4" autoPlay loop muted />
        <div className="header-body">
          <div className="profilePic"></div>
          <h1 className="header-name" id="name">
            Aileen Harvey
          </h1>
          <hr />
          <div className="head-text">
            IVR, Business Recordings, Storybook readings, Advertisements
          </div>
        </div>
        <div className="head-footer" id="head-footer">
          <ul>
            <li>Home</li>
            <li>Services</li>
            <li>About</li>
            <li>Contact</li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Head;

/*
<div className="head-text">
          My name is Aileen Harvey. I am a voice-over professional. I specialize
          in delivering high quality voice-overs via IVR, Business Recordings,
          Storybook readings, Advertisements. I have a talent for adapting to my
          client’s needs, and am able to deliver exceptional results within a
          tight deadline.
        </div>
*/
