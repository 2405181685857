import "./Body.css";
import React, { useEffect, useRef } from "react";
function Body(props) {
  const soundRef = props.soundRef;
  useEffect(() => {
    console.log("Started");
    function onlyPlayOneIn(container) {
      container.addEventListener(
        "play",
        function (event) {
          var audio_elements = container.getElementsByTagName("audio");
          for (var i = 0; i < audio_elements.length; i++) {
            var audio_element = audio_elements[i];
            if (audio_element !== event.target) {
              audio_element.pause();
            }
          }
        },
        true
      );
    }
    onlyPlayOneIn(document.body);
    /*
    document.addEventListener("DOMContentLoaded", function () {
      onlyPlayOneIn(document.body);
    });
    */
  }, []);
  return (
    <>
      <div id="services-container" className="body-container">
        <video playsInline src="/videos/body-video.mp4" autoPlay loop muted />
        <div className="voice-container">
          <div className="recording-container">
            <div className="r-title">
              <h2>IVR</h2>
              <span>
                {" "}
                My name is Aileen Harvey. I am a voice-over professional. I
                specialize in delivering high quality voice-overs via IVR,
                Business Recordings, Storybook readings, Advertisements. I have
                a talent for adapting to my client’s needs, and am able to
                deliver exceptional results within a tight deadline.
              </span>
            </div>
            <div className="r-body">
              <audio controls>
                <source
                  src="/recordings/IVR/Aileen Harvey Commercial Reel.mp3"
                  type="audio/mpeg"
                />
              </audio>

              <audio controls>
                <source
                  src="/recordings/IVR/Aileen Harvey Narrative Reel.mp3"
                  type="audio/mpeg"
                />
              </audio>

              <audio controls>
                <source
                  src="/recordings/IVR/British Heart Foundation USE.mp3"
                  type="audio/mpeg"
                />
              </audio>
              <audio controls>
                <source
                  src="/recordings/IVR/British Heart Foundation.mp3"
                  type="audio/mpeg"
                />
              </audio>
            </div>
          </div>

          <div className="recording-container">
            <div className="r-title">
              <h2>Business Recordings</h2>
              <span>
                {" "}
                My name is Aileen Harvey. I am a voice-over professional. I
                specialize in delivering high quality voice-overs via IVR,
                Business Recordings, Storybook readings, Advertisements. I have
                a talent for adapting to my client’s needs, and am able to
                deliver exceptional results within a tight deadline.
              </span>
            </div>
            <div className="r-body">
              <audio controls>
                <source
                  src="/recordings/Business/Elearning.mp3"
                  type="audio/mpeg"
                />
              </audio>

              <audio ref={soundRef} controls>
                <source
                  src="/recordings/Business/Highland Holidays.mp3"
                  type="audio/mpeg"
                />
              </audio>

              <audio controls>
                <source
                  src="/recordings/Business/Highland Holidays.mp3"
                  type="audio/mpeg"
                />
              </audio>
            </div>
          </div>

          <div className="recording-container">
            <div className="r-title">
              <h2>Storybook Readings</h2>
              <span>
                {" "}
                My name is Aileen Harvey. I am a voice-over professional. I
                specialize in delivering high quality voice-overs via IVR,
                Business Recordings, Storybook readings, Advertisements. I have
                a talent for adapting to my client’s needs, and am able to
                deliver exceptional results within a tight deadline.
              </span>
            </div>
            <div className="r-body">
              <audio controls>
                <source
                  src="/recordings/Story/Country House.mp3"
                  type="audio/mpeg"
                />
              </audio>
            </div>
          </div>

          <div className="recording-container">
            <div className="r-title">
              <h2>Advertisements</h2>
              <span>
                {" "}
                My name is Aileen Harvey. I am a voice-over professional. I
                specialize in delivering high quality voice-overs via IVR,
                Business Recordings, Storybook readings, Advertisements. I have
                a talent for adapting to my client’s needs, and am able to
                deliver exceptional results within a tight deadline.
              </span>
            </div>
            <div className="r-body">
              <audio controls>
                <source
                  src="/recordings/Ad/Race 4 Life.mp3"
                  type="audio/mpeg"
                />
              </audio>

              <audio controls>
                <source
                  src="/recordings/Ad/Revitawell Explainer.mp3"
                  type="audio/mpeg"
                />
              </audio>

              <audio controls>
                <source
                  src="/recordings/Ad/Weight Watchers USE.mp3"
                  type="audio/mpeg"
                />
              </audio>
              <audio controls>
                <source
                  src="/recordings/Ad/Weight Watchers.mp3"
                  type="audio/mpeg"
                />
              </audio>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Body;
