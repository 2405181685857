import logo from "./logo.svg";
import "./App.css";
import Head from "./components/Head";
import Body from "./components/Body";
import About from "./components/About";
import { useRef, useEffect, useState } from "react";

function App() {
  const soundRef = useRef();
  const [played, setPlayed] = useState(false);
  useEffect(() => {
    document.body.addEventListener("click", () => {
      if (!played) {
        setPlayed(true);
      }
    });
    /*
    window.addEventListener("touchstart", () => {
      if (!played) {
        setPlayed(true);
      }
    });
    */
  }, []);

  useEffect(() => {
    if (played) soundRef.current.play();
  }, [played]);
  return (
    <div className="App">
      <Head />
      <Body soundRef={soundRef} />
      <About />
    </div>
  );
}

export default App;
